import React, { useEffect, useState } from 'react';
import { Table, message, Button } from 'antd';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_StudentSubmission } from '../../../types/googleClassroom';
import GradePopup from './GradePopup';

interface Assignment {
  studentName: string;
  startDate: string;
  endDate: string;
  status: string;
  submission: string;
  submissionDetails: GQL_StudentSubmission;
}

interface AssignmentTableProps {
  courseId: string;
  courseWorkId: string;
  style?: React.CSSProperties;
  className: string;
  section: string;
  assignmentName: string;
  assignmentLinks: string[];
  progress: number;
  courseName: string;
  studentNames: { userId: string; fullName: string }[];
  dueDate?: { year: number; month: number; day: number }; // Ensure this prop is passed
}

const AssignmentTable: React.FC<AssignmentTableProps> = ({
  courseId,
  courseWorkId,
  style,
  className,
  section,
  assignmentName,
  assignmentLinks,
  progress,
  studentNames,
  dueDate, // Include dueDate in destructuring
}) => {
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<GQL_StudentSubmission | null>(null);

  const { loading: submissionLoading, error: submissionError, data: submissionData, refetch } = useQuery<{
    getCourseWorkSubmissionList: {
      studentSubmissions: GQL_StudentSubmission[];
    }[];
  }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList,
    {
      variables: { data: [{ courseId, courseWorkId }] },
      onError: (error) => {
        message.error(
          error.message ||
          'There was an error loading the submissions. Please check your connection and try again later.'
        );
      },
      onCompleted: (data) => {
        console.log('data refetched: ', data);
        if (data && data.getCourseWorkSubmissionList.length > 0) {
          const submissions = data.getCourseWorkSubmissionList[0].studentSubmissions || [];
          const transformedData = submissions.map((submission) => {
            const creationTime = submission.creationTime || null;
            const updateTime = submission.updateTime || null;

            // Ensure studentName is always a string
            const studentName = studentNames.find(student => student.userId === submission.userId)?.fullName || submission.userName || 'Unknown Student';

            return {
              studentName,
              startDate: creationTime ? new Date(creationTime).toLocaleDateString() : 'N/A',
              endDate: updateTime ? new Date(updateTime).toLocaleDateString() : 'N/A',
              status: submission.state === 'TURNED_IN' ? 'Completed' : 'Pending',
              submission: submission.assignedGrade !== null ? 'Graded' : 'Not Graded',
              submissionDetails: submission,
            };
          });
          setAssignments(transformedData);
        }
      },
    }
  );

  useEffect(() => {
    if (submissionData && submissionData.getCourseWorkSubmissionList.length > 0) {
      const submissions = submissionData.getCourseWorkSubmissionList[0].studentSubmissions || [];
      const transformedData = submissions.map((submission) => {
        const creationTime = submission.creationTime || null;
        const endDate = dueDate ? `${dueDate.month}/${dueDate.day}/${dueDate.year}` : 'N/A';

        // Ensure studentName is always a string
        const studentName = studentNames.find(student => student.userId === submission.userId)?.fullName || submission.userName || 'Unknown Student';

        return {
          studentName,
          startDate: creationTime ? new Date(creationTime).toLocaleDateString() : 'N/A',
          endDate,
          status: submission.state === 'TURNED_IN' ? 'Completed' : 'Pending',
          submission: submission.assignedGrade !== null ? 'Graded' : 'Not Graded',
          submissionDetails: submission,
        };
      });
      setAssignments(transformedData);
    }
  }, [submissionData, studentNames, dueDate]);

  const showModal = (submission: GQL_StudentSubmission) => {
    setSelectedSubmission(submission);
    setIsModalVisible(true);
  };

  const updateGradedSubmission = (id: string, gradedScore: number) => {
    setAssignments(prevAssignments =>
      prevAssignments.map(assignment =>
        assignment.submissionDetails.id === id
          ? { ...assignment, submission: 'Graded' }
          : assignment
      )
    );
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
      width: isMobile? '80%' : '25%',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '25%',
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: '25%',
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '25%',
      onCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      onHeaderCell: () => ({
        style: isMobile ? { display: 'none' } : {},
      }),
      render: (text: string) => (
        <span style={{ color: text === 'Completed' ? 'green' : 'orange' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Submission',
      dataIndex: 'submission',
      key: 'submission',
      width: isMobile? '80%' : '25%',
      render: (text: string, record: Assignment) => (
        <Button type="primary" style={{ width: '100px' }} onClick={() => showModal(record.submissionDetails)}>
          {text}
        </Button>
      ),
    },
  ];

  if (submissionLoading) {
    return <p>Loading...</p>;
  }

  if (submissionError) {
    return <p>Error: {submissionError.message}</p>;
  }
  const expandedRowRender = (record: any) => {
    return (
      <div>
        {columns.map((column) => {

          if ('dataIndex' in column) {
            return (
              <div key={column.key} style={{ marginBottom: '8px' }}>
                <strong>{column.title}:</strong> {record[column.dataIndex as keyof typeof record]}
              </div>
            );
          }
          return null; 
        })}
      </div>
    );
  };
  return (
    <div style={{ width: '100%', ...style }}>
     {!isMobile && (<><Table
        columns={columns}
        dataSource={assignments}
        rowKey="studentName"
        pagination={false}
      /></>) }
        {isMobile && (<><Table
        columns={columns}
        dataSource={assignments}
        rowKey="studentName"
        pagination={false}
        expandable={{
          expandedRowRender,

        }}
      /></>) }
      {selectedSubmission && (
        <GradePopup
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          studentName={selectedSubmission.userName || 'Unknown Student'}
          className={className}
          section={section}
          submissionData={selectedSubmission}
          assignmentName={assignmentName}
          assignmentLinks={assignmentLinks}
          progress={progress}
          courseId={courseId}
          courseWorkId={courseWorkId}
          updateGradedSubmission={updateGradedSubmission}
          submission={selectedSubmission.state === 'TURNED_IN' ? 'Graded' : 'Not Graded'}
          refetch= {refetch}
        />
      )}
    </div>
  );
};

export default AssignmentTable;
