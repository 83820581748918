import { useLazyQuery } from '@apollo/client';
import { Layout, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import './App.css';
import { gqlSchema } from './gql/schema';
import { useAuth } from './hooks/useAuth';
import Header from './shared/AuthenticatedHeader';
import AdvancedSearchHeader from './shared/AdvancedSearchHeader';
import { themeConfig } from './utils/theme';
import ExpiredSubscriptionModal from './shared/ExpiredSubscriptionModal';
import AdiFooter from './shared/AdiFooter';

const App: React.FC = (props) => {
  const { isLogged, isStudent, updateUser, isAdvancedSearch } = useAuth();
  const hasAuthenticatedHeader = isLogged && !isAdvancedSearch;

  const [fetchProfile] = useLazyQuery(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.me, {
    onCompleted: (data) => {
      updateUser(data.me);
    },
    // since `useAuth` makes the same request and handles the error, we do not handle it here
    // onError: (e) => {
    //   message.error('Error fetching profile: ' + e.graphQLErrors[0]);
    // },
    fetchPolicy: 'cache-and-network',
  });

  const hubspotWidget = (window as any).HubSpotConversations?.widget;
  useEffect(() => {
    if (isStudent) {
      hubspotWidget?.remove();
    } else {
      hubspotWidget?.load();
    }
  }, [isStudent, hubspotWidget]);

  useEffect(() => {
    if (hasAuthenticatedHeader) {
      fetchProfile();
    }
  }, [fetchProfile, hasAuthenticatedHeader]);

  message.config({
    duration: 3,
    top: 80,
    maxCount: 1, // show only one error message
  });

  const contentClass = useMemo(() => {
    if (hasAuthenticatedHeader || isAdvancedSearch) {
      return 'content-span';
    }

    return '';
  }, [hasAuthenticatedHeader, isAdvancedSearch]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <ThemeProvider theme={themeConfig}>
      <Layout style={{ minHeight: '100vh', background: '#F5F5FB',gap: '1rem' }}>
        {hasAuthenticatedHeader && <Header />}
        {isAdvancedSearch && <AdvancedSearchHeader />}
        {!isMobile && (<><Layout.Content style={{ flex: 1, padding: '0 24px', marginTop: hasAuthenticatedHeader ? '64px' : '0', gap: '1rem' }}>
          <span role="none" className={contentClass}>{props.children}</span>
        </Layout.Content></>)}
        {isMobile && (<><Layout.Content style={{ flex: 1, padding: '0 16px', marginTop: hasAuthenticatedHeader ? '10px' : '0' }}>
          <span role="none" className={contentClass}>{props.children}</span>
        </Layout.Content></>)}
        {!isMobile && (<><Layout.Footer style={{ textAlign: 'center', padding: '0' }}>
          <AdiFooter />
        </Layout.Footer></>)}
        {isMobile && (<><Layout.Footer style={{ textAlign: 'center', padding: '0' }}>
          <AdiFooter />
        </Layout.Footer></>)}
      </Layout>
      <ExpiredSubscriptionModal />
    </ThemeProvider>
  );
};

export default App;
