import React, { useCallback, useEffect, useMemo } from 'react';
import { Form as AntdForm, Col, Row, Popover, message } from 'antd';
import { useQuery } from '@apollo/client';
import { FormInstance } from 'antd/lib/form/Form';
import Form from '../../Form';
import Input from '../../Input';
import Button from '../../Button';
import { ClassProps } from '../../../types/class';
import * as S from './styles';
import { gqlSchema } from '../../../gql/schema';
import { GQL_UserAssignLimitsResponse } from '../../../types/user';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';

interface Props {
  form: FormInstance<ClassProps>;
  onFinishClass: (data: ClassProps) => void;
  classInfo?: ClassProps | null;
  initialInvite: any[];
  'data-cy': string;
}

const ClassInfo: React.FC<Props> = (props) => {
  const { onFinishClass, classInfo, form, initialInvite } = props;

  const onFinish = useCallback(
    async (values: ClassProps) => {
      onFinishClass(values);
    },
    [onFinishClass],
  );

  useEffect(() => {
    if (classInfo) form.setFieldsValue({ invites: classInfo.invites });
  }, [form, classInfo]);

  const history = useHistory();
  const { isSubscriptionUser } = useAuth();

  const { data: userAssignLimits } = useQuery<{
    getUserAssigmentsLimits: GQL_UserAssignLimitsResponse;
  }>(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits, {
    onError: (error) => {
      message.error(`There was an error in fetching user assignment limits: ${error.message || 'Unexpected Error'}`);
    },
  });

  const actionDisabled = useMemo(() => {
    if (isSubscriptionUser) {
      if (
        userAssignLimits?.getUserAssigmentsLimits.totalAssignes.classesAssigned! >=
        userAssignLimits?.getUserAssigmentsLimits.assignLimits.maxClasses!
      ) {
        return true;
      }
    }
    return false;
  }, [userAssignLimits, isSubscriptionUser]);

  const subscriptionPage = useCallback(() => {
    history.push('/manage-subscription');
  }, [history]);

  const invitesLength = form.getFieldValue('invites')?.length;

  const saveButton = useMemo(
    () => (
      <AntdForm.Item>
        <Popover
          placement="top"
          trigger={actionDisabled ? 'hover' : ''}
          content={
            <>
              <p style={{ marginBottom: 10 }}>Upgrade your subscription to be able to create more classes</p>
              <Button text="Upgrade" onClick={subscriptionPage} block />
            </>
          }
        >
          <div style={{ width: '100%' }}>
            <Button
              block
              text={invitesLength > 0 ? 'Setup Students' : 'Create'}
              onClick={form.submit}
              disabled={actionDisabled}
            />
          </div>
        </Popover>
      </AntdForm.Item>
    ),
    [form, invitesLength, actionDisabled, subscriptionPage],
  );

  const renderButtons = useCallback(() => {
    return (
      <Row gutter={16} justify="center">
        <Col span={12}>
          {saveButton}
        </Col>
      </Row>
    );
  }, [saveButton]);

  return (
    <Form onFinish={onFinish} form={form} initialValues={{ invites: initialInvite }} data-cy={props['data-cy']}>
      <Row gutter={24} justify="center">
        <Col span={10} lg={10} xl={10} xs={24}>
          <S.TitleInput>Class Name</S.TitleInput>
          <AntdForm.Item
            data-cy="components-classes-row-create-class-name"
            name="name"
            initialValue={classInfo?.name}
            rules={[
              {
                required: true,
                message: 'Please type the class name!',
              },
            ]}
          >
            <Input placeholder="Type the class name here..." />
          </AntdForm.Item>
        </Col>
        <Col span={10} lg={10} xl={10} xs={24}>
          <S.TitleInput>Description</S.TitleInput>
          <AntdForm.Item
            data-cy="components-classes-row-create-class-description"
            name="description"
            initialValue={classInfo?.description}
            rules={[
              {
                required: true,
                message: 'Please type the class description!',
              },
            ]}
          >
            <Input placeholder="Type the class description here..." />
          </AntdForm.Item>
        </Col>
        <Col span={10} lg={10} xl={10} xs={24}>
          <S.TitleInput>Room</S.TitleInput>
          <AntdForm.Item
            data-cy="components-classes-row-create-class-room"
            name="room"
            initialValue={classInfo?.room}
            rules={[
              {
                required: true,
                message: 'Please type the room number!',
              },
            ]}
          >
            <Input placeholder="Type the room number here..." />
          </AntdForm.Item>
        </Col>
        <Col span={10} lg={10} xl={10} xs={24}>
          <S.TitleInput>Section</S.TitleInput>
          <AntdForm.Item
            data-cy="components-classes-row-create-class-section"
            name="section"
            initialValue={classInfo?.section}
            rules={[
              {
                required: true,
                message: 'Please type the section!',
              },
            ]}
          >
            <Input placeholder="Type the section here..." />
          </AntdForm.Item>
        </Col>
        <Col span={16}>
          {renderButtons()}
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(ClassInfo);
