import { Form, Col, Row, Empty } from 'antd';
import Input from '../../Input';
import Button from '../../Button';
import * as S from './styles';
import { UserDataProps } from '../../../types/user';
import { TagInput as TagInputType } from '../../../types/tags';
import React, { useEffect, useState } from 'react';

interface Props {
  userType: string;
  onTagUpdate?: (tags: TagInputType[], userIndex: number) => void;
  onUserListUpdate?: () => void;
  currentUsers?: any;
}

const UsersData: React.FC<Props> = (props) => {
  const { userType, currentUsers, onUserListUpdate } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Row style={{ height: '40vh', overflowX: 'hidden', overflowY: 'auto' }}>
      <Col span={24}>
        <Form.List
          name="invites"
          rules={[
            {
              validator: async (_, invites = []) => {
                const emails = invites.map((user: UserDataProps) => user?.email);
                let invalidEmails = '';
                emails.forEach((userEmail: string) => {
                  const existEmail = emails.filter((email: string) => userEmail && email === userEmail);

                  if (existEmail.length > 1) invalidEmails = `Email ${userEmail} is repeated`;
                });
                if (invalidEmails) {
                  return Promise.reject(new Error(invalidEmails));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
           {!isMobile && (<>{fields.length > 0 && (
                <Row gutter={24}>
                  <Col span={8}>
                    <S.TitleInput>First Name</S.TitleInput>
                  </Col>
                  <Col span={8}>
                    <S.TitleInput>Last Name</S.TitleInput>
                  </Col>
                  <Col span={8}>
                    <S.TitleInput>Email/Username</S.TitleInput>
                  </Col>
                </Row>
              )}</>)}   
              {fields.map((field, index) => (
                <Row gutter={16} key={`${index}${field.key}${currentUsers?.length}`} data-cy="component-user-data-student-row">
                  <Col span={8} xl={8} lg={8} md={8} xs={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'firstName']}
                      fieldKey={[field.fieldKey, 'firstName']}
                      rules={[{ required: true, message: 'Missing first name' }]}
                    >
                      <Input data-cy="shared-inviteusers-userdata-firstName-input" placeholder="Type first name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}  xl={8} lg={8} md={8} xs={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'lastName']}
                      fieldKey={[field.fieldKey, 'lastName']}
                      rules={[{ required: true, message: 'Missing last name' }]}
                    >
                      <Input data-cy="shared-inviteusers-userdata-lastName-input" placeholder="Type last name" size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={8}  xl={8} lg={8} md={8} xs={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'email']}
                      fieldKey={[field.fieldKey, 'email']}
                      rules={[{ required: true, message: 'Missing email or username' }]}
                    >
                      <Input data-cy="shared-inviteusers-userdata-email-input" placeholder="Type the email or username" />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              {!fields.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Click below to add ${userType}s`} />}
              <Row>
                <Col span={24}>
                  <Form.ErrorList errors={errors} />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    onClick={() => {
                      add();
                      onUserListUpdate?.();
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};

export default React.memo(UsersData);
