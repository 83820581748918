import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Badge } from 'antd';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import { GQL_GoogleClassroomCourse, CourseState } from '../../../../types/googleClassroom';
import { formatDateTime } from '../../../../utils/date';
import { themeConfig } from '../../../../utils/theme';
import { useAuth } from '../../../../hooks/useAuth';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { CalendarOutlined, TeamOutlined } from '@ant-design/icons';

interface Props {
  classObj: GQL_GoogleClassroomCourse;
  icon: React.ReactNode;
}

const Item: React.FC<Props> = ({ classObj, icon }) => {
  const history = useHistory();
  const { isFacilitator } = useAuth();
  const [numberOfStudents, setNumberOfStudents] = useState<number | undefined>(undefined);

  const { loading: studentsLoading } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudents,
    {
      variables: { courseId: classObj.id },
      skip: !classObj.id,
      onCompleted: (data) => {
        const students = data?.getCourseStudents?.students;
        if (students) {
          setNumberOfStudents(students.length);
        }
      },
    }
  );

  const goToDashboard = useCallback(() => {
    history.push('/googleclassroom-teacher-dashboard/class/' + classObj.id, {
      className: classObj.name,
    });
  }, [classObj, history]);

  const courseState = classObj.courseState === CourseState.ACTIVE ? 'Ongoing' : classObj.courseState;
  const courseStateStyle = {
    color: classObj.courseState === CourseState.ACTIVE ? '#3EBC89' : 'inherit',
    fontWeight: classObj.courseState === CourseState.ACTIVE ? 'bold' as 'bold' : 'normal' as 'normal',
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    console.log('Resize detected:', window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <S.ClassCard>
      <Row gutter={[16, 16]} align="middle">
        <Col span={24}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '16px' }}>
            <S.MainIconWrapper style={{ marginRight: '8px' }}>{icon}</S.MainIconWrapper>
            <S.Title style={{ flexGrow: 1, marginBottom: '8px', marginRight: '8px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {classObj.name}
            </S.Title>
            <S.Info $align="right" style={{ ...courseStateStyle, whiteSpace: 'nowrap', flexShrink: 0 }}>
              {courseState}
            </S.Info>
          </div>
        </Col>
      </Row>

      <div style={{ height: '16px' }} />
      <Row justify="center" gutter={[24, 24]}>
      {classObj.section &&
      <Col span={8}>
          <S.Info $fontSize="0.85em">
            <Badge
               count={
                classObj.section !== null ? (
                  <div style={{ textAlign: 'center' }}>
                    Section: {classObj.section}
                  </div>
                ) : null
              }
              style={{ backgroundColor: '#20BC89', fontSize: '0.85em', color: '#fff', borderRadius: '40px',fontWeight: 500,padding: isMobile? '8px 12px' :'8px 16px', }}
            />
          </S.Info>
        </Col>
      }
        <Col span={8}>
          <S.Info $fontSize="0.85em">
            <TeamOutlined style={{ marginRight: '8px' }} />
            {studentsLoading ? 'Loading...' : numberOfStudents !== undefined ? `${numberOfStudents} Students` : 'Unknown'}
          </S.Info>
        </Col>
        <Col span={8}>
          <S.Info $fontSize="0.85em">
            <CalendarOutlined style={{ marginRight: '8px' }} />
            {formatDateTime(classObj.creationTime)}
          </S.Info>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '3em' }}>
        <Col span={16}>
          <S.DashboardButton
            data-cy={`components-item-button`}
            text={`${isFacilitator ? 'Course or Event' : 'Class'} Dashboard`}
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.ClassCard>
  );
};

export default Item;
