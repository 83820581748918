import styled from 'styled-components';

export const Container = styled.div<{ fullHeight?: boolean; padding?: number }>`
  padding: ${(props) => (props.padding ? `${props.padding}em` : '4em')};
  ${(props) => props.fullHeight && 'height: calc(100vh - 120px);'}
@media (max-width: 768px) {
  padding:0px;
  margin-Top:80px;
  /* mobile-specific styles here */ 
}
`;

export const Title = styled.div`
  margin-left: 5px;
  display: inline-block;
  vertical-align: sub;
  width: 100%;
  font-weight: 500;
  font-size: 1.5em;
`;

export const BackButtonContainer = styled.div`
  width: 24px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  & > svg {
    vertical-align: middle;
  }
`;
